<template>
    <div>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            :filename="FileName"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-content-width="100%"
            :htmlToPdfOptions="pdfOption"
            ref="html2Pdf">
            
            <section slot="pdf-content">
                <div class="pdf-default">
                    <table border="1" class="mytable mytable-head">
                        <thead>
                            <tr>
                                <td class="px-3" width="5%" style="text-align: center;"><label>Tanggal Invoice</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>No. Faktur</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>No. SJ</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>No. Faktur Pajak</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Kode Barang</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Nama Item</label></td>
                                <td class="px-3" width="5%" style="text-align: center;"><label>Quantity</label></td>
                                <td class="px-3" width="5%" style="text-align: center;"><label>Quantity Satuan</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Harga</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>DPP</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>PPN Out</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Piutang</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Keterangan</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Jatuh Tempo</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>No. PO</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Outstanding</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Status Pembayaran</label></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in items" :key="index">
                                <td class="px-2" >
                                    <label>
                                        {{item['invoice_date'] == "" || item['invoice_date'] == null ? '-' : 
                                        new Date(item['invoice_date']).toLocaleDateString('es-CL') }}
                                    </label>
                                </td>
                                <td class="px-2"><label>{{ item['invoice_number'] }}</label></td>
                                <td class="px-2"><label>{{ item['delivery_order_number'] }}</label></td>
                                <td class="px-2"><label>{{ item['tax_invoice'] }}</label></td>
                                <td class="px-2"><label>{{ item['product_code'] }}</label></td>
                                <td class="px-2"><label>{{ item['item_name'] }}</label></td>
                                <td class="px-2"><label>{{ item['fulfilled_quantity'] }}</label></td>
                                <td class="px-2"><label>{{ item['total_conv_amount'] }}</label></td>
                                <td class="px-2"><label>{{ item['price'] }}</label></td>
                                <td class="px-2"><label>{{ item['price_discount'] }}</label></td>
                                <td class="px-2"><label>{{ item['ppn_out'] }}</label></td>
                                <td class="px-2"><label>{{ item['vat'] }}</label></td>
                                <td class="px-2"><label>{{ item['customer_name'] }}</label></td>
                                <td class="px-2" >
                                    <label>
                                        {{item['due_date'] == "" || item['due_date'] == null ? '-' : 
                                        new Date(item['due_date']).toLocaleDateString('es-CL') }}
                                    </label>
                                </td>
                                <td class="px-2"><label>{{ item['purchase_order_number'] }}</label></td>
                                <td class="px-2"><label>{{ item['outstanding_amount'] }}</label></td>
                                <td class="px-2"><label>{{ item['status_payment'] }}</label></td>  
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import moment from 'moment';
import { response } from '../../infrastructure/constant/response.js';
import TransactionInvoiceReportService from '../../Areas/Reporting/TransactionInvoiceReport/Script/TransactionInvoiceReportService.js';

export default {
    name: 'PurchasingInvoiceReportPDF',
    components: {
        'vue-html2pdf': VueHtml2pdf
    },
    data(){
        return{
            FileName: "ReportTransaksiInvoice_" + moment(new Date()).format("YYYY-MM-DD-HHmmss"),
            items: [],
            pdfOption: {
                margin: [5,5],
                html2canvas: {
                    scale: 4,
                    useCORS: true
                },
                jsPDF: {
                    unit: 'mm',
                    format: 'a3',
                    orientation: 'landscape'
                },
                pagebreak: {
                    avoid: ['tr', 'css', 'legacy']
                },
            }
        }
    },
    methods: {
        async generatePDF(gridData) {
            var itemPDF = TransactionInvoiceReportService.itemPDF(gridData);
            this.items = itemPDF;

            if(this.items == null) {
                this.$swal("Error", response.noRecord, "error")
            }
            else {
                this.$refs.html2Pdf.generatePdf();
            }
        },
    }
}
</script>

<style scoped>
.col-sm-dot, .col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 30%;
    }
    .col-sm-dot {
        width: 5%;
    }
    .col-sm-content {
        width: 60%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.mytable {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
}
.mytable-head {
    border: 1px solid white;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-head td {
    border: 1px solid black;
}
.mytable-body {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-body td {
    border: 1px solid black;
    border-top: 0;
}
.mytable-footer {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
.mytable-footer td {
    border: 1px solid black;
    border-top: 0;
}
</style>