<template>
    <div>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            :filename="FileName"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-content-width="100%"
            :htmlToPdfOptions="pdfOption"
            ref="html2Pdf">
            
            <section slot="pdf-content">
                <div class="pdf-default">
                    <table border="1" class="mytable mytable-head">
                        <thead>
                            <tr>
                                <td class="px-3" width="2%" style="text-align: center;"><label>Status</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>No. PO</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>NO. SP</label></td>
                                <td class="px-3" width="15%" style="text-align: center;"><label>Nama Customer</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Tipe</label></td>
                                <td class="px-3" width="15%" style="text-align: center;"><label>Nama Project</label></td>
                                <td class="px-3" width="5%" style="text-align: center;"><label>Cara Bayar</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Sales</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>No. Invoice</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Kode Barang</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Tipe Barang</label></td>
                                <td class="px-3" width="20%" style="text-align: center;"><label>Nama Barang</label></td>    
                                <td class="px-3" width="5%" style="text-align: center;"><label>Jumlah</label></td>
                                <td class="px-3" width="5%" style="text-align: center;"><label>Satuan</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Total</label></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in items" :key="index">
                                <td class="px-2"><label>{{ item['status'] }}</label></td>
                                <td class="px-2"><label>{{ item['purchase_order_number'] }}</label></td>
                                <td class="px-2"><label>{{ item['sp_number'] }}</label></td>
                                <td class="px-2"><label>{{ item['customer_name'] }}</label></td>
                                <td class="px-2"><label>{{ item['project_type'] }}</label></td>
                                <td class="px-2"><label>{{ item['project_name'] }}</label></td>
                                <td class="px-2"><label>{{ item['payment_method'] }}</label></td>
                                <td class="px-2"><label>{{ item['sales_name'] }}</label></td>
                                <td class="px-2"><label>{{ item['invoice_number'] }}</label></td>
                                <td class="px-2"><label>{{ item['product_code'] }}</label></td>
                                <td class="px-2"><label>{{ item['group_type_name'] }}</label></td>
                                <td class="px-2"><label>{{ item['item_name'] }}</label></td>
                                <td class="px-2"><label>{{ item['quantity'] }}</label></td>
                                <td class="px-2"><label>{{ item['uom'] }}</label></td>
                                <td class="px-2"><label>{{ item['price_discount'] }}</label></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import moment from 'moment';
import { response } from '../../infrastructure/constant/response.js';
import SalesReportService from '../../Areas/Reporting/SalesReport/Script/SalesReportService';

export default {
    name: 'SalesReportPDF',
    components: {
        'vue-html2pdf': VueHtml2pdf
    },
    data(){
        return{
            FileName: "ReportSales_" + moment(new Date()).format("YYYY-MM-DD-HHmmss"),
            items: [],
            pdfOption: {
                margin: [5,5],
                html2canvas: {
                    scale: 4,
                    useCORS: true
                },
                jsPDF: {
                    unit: 'mm',
                    format: 'a2',
                    orientation: 'landscape'
                },
                pagebreak: {
                    avoid: ['tr', 'css', 'legacy']
                },
            }
        }
    },
    methods: {
        async generatePDF(gridData) {
            var itemsPDF = SalesReportService.itemPDF(gridData)
            this.items = itemsPDF;
            if(this.items == null){
                this.$swal("Error", response.noRecord, "error")
            }
            else{
                this.$refs.html2Pdf.generatePdf();
            }
        },
    }
}
</script>

<style scoped>
.col-sm-dot, .col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 30%;
    }
    .col-sm-dot {
        width: 5%;
    }
    .col-sm-content {
        width: 60%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.mytable {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
}
.mytable-head {
    border: 1px solid white;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-head td {
    border: 1px solid black;
}
.mytable-body {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-body td {
    border: 1px solid black;
    border-top: 0;
}
.mytable-footer {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
.mytable-footer td {
    border: 1px solid black;
    border-top: 0;
}
</style>