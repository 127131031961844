<template>
    <div>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            :filename="FileName"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-content-width="100%"
            :htmlToPdfOptions="pdfOption"
            ref="html2Pdf">
            
            <section slot="pdf-content">
                <div class="pdf-default">
                    <table border="1" class="mytable mytable-head">
                        <thead>
                            <tr>
                                <td class="px-3" width="20%" style="text-align: center;"><label>No. Delivery Order</label></td>
                                <td class="px-3" width="20%" style="text-align: center;"><label>Status</label></td>
                                <td class="px-3" width="20%" style="text-align: center;"><label>Tanggal Delivery ORder</label></td>
                                <td class="px-3" width="20%" style="text-align: center;"><label>Tanggal Terima Delivery Order</label></td>
                                <td class="px-3" width="20%" style="text-align: center;"><label>Invoice</label></td>
                                <td class="px-3" width="20%" style="text-align: center;"><label>Supplier</label></td>
                                <td class="px-3" width="20%" style="text-align: center;"><label>Storage</label></td>
                                <td class="px-3" width="20%" style="text-align: center;"><label>Keterangan</label></td>
                                <td class="px-3" width="20%" style="text-align: center;"><label>Printed Notes</label></td>
                                <td class="px-3" width="20%" style="text-align: center;"><label>Alamat</label></td>
                                <td class="px-3" width="20%" style="text-align: center;"><label>Kode Produk</label></td>
                                <td class="px-3" width="20%" style="text-align: center;"><label>Nama Produk</label></td>
                                <td class="px-3" width="20%" style="text-align: center;"><label>UOM</label></td>
                                <td class="px-3" width="20%" style="text-align: center;"><label>Kuantitas</label></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in items" :key="index">
                                <td class="px-2"><label>{{ item['delivery_order_number'] }}</label></td>
                                <td class="px-2"><label>{{ item['status'] }}</label></td>
                                <td class="px-2" >
                                    <label>
                                        {{item['delivery_order_date'] == "" || item['delivery_order_date'] == null ? '-' : 
                                        new Date(item['delivery_order_date']).toLocaleDateString('es-CL') }}
                                    </label>
                                </td>
                                <td class="px-2" >
                                    <label>
                                        {{item['delivery_order_received_date'] == "" || item['delivery_order_received_date'] == null ? '-' : 
                                        new Date(item['delivery_order_received_date']).toLocaleDateString('es-CL') }}
                                    </label>
                                </td>
                                <td class="px-2"><label>{{ item['invoice_number'] }}</label></td>
                                <td class="px-2"><label>{{ item['supplier_name'] }}</label></td>
                                <td class="px-2"><label>{{ item['storage_name'] }}</label></td>
                                <td class="px-2"><label>{{ item['notes'] }}</label></td>
                                <td class="px-2"><label>{{ item['printed_notes'] }}</label></td>
                                <td class="px-2"><label>{{ item['address'] }}</label></td>
                                <td class="px-2"><label>{{ item['product_code'] }}</label></td>
                                <td class="px-2"><label>{{ item['item_code'] }}</label></td>
                                <td class="px-2"><label>{{ item['uom'] }}</label></td>
                                <td class="px-2"><label>{{ item['quantity'] }}</label></td>          
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import moment from 'moment';
import { response } from '../../infrastructure/constant/response';

export default {
    name: 'PurchasingDeliveryOrderReportPDF',
    components: {
        'vue-html2pdf': VueHtml2pdf
    },
    data(){
        return{
            FileName: "PurchasingDeliveryOrderReport_" + moment(new Date()).format("YYYY-MM-DD-HHmmss"),
            items: [],
            pdfOption: {
                margin: [5,5],
                html2canvas: {
                    scale: 4,
                    useCORS: true
                },
                jsPDF: {
                    unit: 'mm',
                    format: 'a3',
                    orientation: 'landscape'
                },
                pagebreak: {
                    avoid: ['tr', 'css', 'legacy']
                },
            }
        }
    },
    methods: {
        async generatePDF(gridData) {
            this.items = gridData;
            if(this.items.length == 0) {
                this.$swal("Error", response.noRecord, "error");
            }
            else{
                this.$refs.html2Pdf.generatePdf();
            } 
        },
    }
}
</script>

<style scoped>
.col-sm-dot, .col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 30%;
    }
    .col-sm-dot {
        width: 5%;
    }
    .col-sm-content {
        width: 60%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.mytable {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
}
.mytable-head {
    border: 1px solid white;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-head td {
    border: 1px solid black;
}
.mytable-body {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-body td {
    border: 1px solid black;
    border-top: 0;
}
.mytable-footer {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
.mytable-footer td {
    border: 1px solid black;
    border-top: 0;
}
</style>