import { render, staticRenderFns } from "./TransactionInvoicePaymentReportPdfTemplate.vue?vue&type=template&id=36dd0d74&scoped=true"
import script from "./TransactionInvoicePaymentReportPdfTemplate.vue?vue&type=script&lang=js"
export * from "./TransactionInvoicePaymentReportPdfTemplate.vue?vue&type=script&lang=js"
import style0 from "./TransactionInvoicePaymentReportPdfTemplate.vue?vue&type=style&index=0&id=36dd0d74&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36dd0d74",
  null
  
)

export default component.exports